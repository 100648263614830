import { State } from 'shared/core';

export class MeasurementGuideValidator {
  private state: State = window.App.getProvider(State);

  // hard limits
  private hardLimits: Record<string, number[]> = {
    chest:      [0.243, 0.839],
    waist:      [0.180, 0.775],
    hip:        [0.257, 0.802],
    inside_leg: [0.35, 0.55]
  };

  private heightHardLimits: Record<string, Record<string, any>> = {
    male: {
      age: 16,
      min: { mul: 4.7, add: 50 },
      max: { mul: 6.8, add: 110 }
    },
    female: {
      age: 15,
      min: { mul: 4.2, add: 50 },
      max: { mul: 6.8, add: 110 }
    }
  };

  // soft limits
  private softLimits: Record<string, number[]> = {
    chest:      [0.300, 0.731],
    waist:      [0.234, 0.667],
    hip:        [0.312, 0.748],
    inside_leg: [0.4, 0.5]
  };

  private heightSoftLimits: Record<string, Record<string, any>> = {
    male: {
      age: 16,
      min: { mul: 4.7, add: 60 },
      max: { mul: 6.8, add: 100 }
    },
    female: {
      age: 15,
      min: { mul: 4.2, add: 60 },
      max: { mul: 6.5, add: 100 }
    }
  };

  constructor() {
    $('#child_form #complete_measurement_step').unbind().on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      let canSubmit = this.checkSoftLimitsBeforeSubmitting($('#child_form'));

      if(canSubmit) {
        window.App.dispatch({ name: 'MEASUREMENTS_FORM_SUBMIT' });
      }
    });
  }

  public checkSoftLimitsBeforeSubmitting(form: JQuery): boolean {
    const height: number = parseFloat($('#child_measurement_height_cm').val() as string);
    const gender: string = ($('#child_gender').val() as string).replace('neutral', 'male');
    const age: number = this.calculateChildAge(gender, $('#child_birthday').val() as string);

    let heightExceeded = false;
    let heightHardExceeded = false;
    let otherExceeded = [];
    let otherHardExceeded = [];

    // checking soft limits
    for (let sl in this.softLimits) {
      let min: number = this.softLimits[sl][0] * height;
      let max: number = this.softLimits[sl][1] * height;

      let el = $(`#child_measurement_${sl}_cm`);

      if (!el[0]) continue; // If we're missing bar or value element then we skip initialization

      let val: number = parseFloat(el.val() as string);

      if (val < min || val > max) {
        otherExceeded.push(sl.toString());
      }
    }

    for (let hl in this.hardLimits) {
      let min: number = this.hardLimits[hl][0] * height;
      let max: number = this.hardLimits[hl][1] * height;

      let el: JQuery = $(`#child_measurement_${hl}_cm`);

      if (!el[0]) continue; // If we're missing bar or value element then we skip initialization

      let val = parseFloat(el.val() as string);

      if (val < min || val > max) {
        otherHardExceeded.push(hl.toString());
      }
    }

    // checking soft limits for height measurement
    const h_min = this.heightSoftLimitFormula(gender, 'min', age);
    const h_max = this.heightSoftLimitFormula(gender, 'max', age);

    // checking hard limits for height measurement
    const h_min_hard = this.heightHardLimitFormula(gender, 'min', age);
    const h_max_hard = this.heightHardLimitFormula(gender, 'max', age);
    const h_cm = height;

    if (h_cm < h_min || h_cm > h_max) {
      heightExceeded = true;
    }

    if (h_cm < h_min_hard || h_cm > h_max_hard) {
      heightHardExceeded = true;
    }

    if (otherExceeded.length > 0 || heightExceeded) {
      if (otherHardExceeded.length > 0 || heightHardExceeded) {
        // Hard Limit
        window.App.dispatch({ name: 'MEASUREMENTS_VALIDATION', payload: { name: 'hardLimit', type: 'open' } });
        $('p.warning_note').html(this.buildExceededHardWarningNote(heightHardExceeded, otherHardExceeded));
      } else {
        // Soft Limit
        window.App.dispatch({ name: 'MEASUREMENTS_VALIDATION', payload: { name: 'softLimit', type: 'open' } });
        $('p.wait_note').hide();
        $('p.warning_note').html(this.buildExceededWarningNote(heightExceeded, otherExceeded));
      }

      return false;
    } else {
      return true;
    }
  }

  private buildExceededWarningNote(hardExceeded: boolean, otherHardExceeded: Array<string>): string {
    const measurements = [];

    if (hardExceeded) {
      measurements.push('height');
    }

    for (let item of otherHardExceeded) {
      measurements.push(item.replace('_', ' '));
    }

    let sentence = 'You may wish to recheck the ';

    if (measurements.length == 1) {
      sentence += measurements[0] + ' measurement ';
    } else {
      sentence += measurements.slice(0, measurements.length - 1).join(', ') + ' and ' + measurements.slice(-1) + ' measurements ';
    }

    sentence += ' that you have entered before continuing.';

    return sentence;
  }

  private buildExceededHardWarningNote(he: boolean, oe: Array<string>): string {
    return this.buildExceededWarningNote(he, oe).replace('You may wish to', 'Please');
  }

  private calculateChildAge(gender: string, birthday: string): number {
    const today: number = new Date().valueOf();
    const birthDate: number = new Date(birthday).valueOf();
    const diff: number = (today - birthDate) / (1000 * 60 * 60 * 24 * 365);
    const age = parseFloat(new Date(diff).valueOf().toFixed(1));

    return age > this.heightSoftLimits[gender]['age'] ? this.heightSoftLimits[gender]['age'] : age;
  }

  private heightSoftLimitFormula(gender: string, type: string, age: number): number {
    const mul = this.heightSoftLimits[gender][type]['mul'];
    const add = this.heightSoftLimits[gender][type]['add'];

    return (age * mul) + add;
  }

  private heightHardLimitFormula(gender: string, type: string, age: number): number {
    const mul = this.heightHardLimits[gender][type]['mul'];
    const add = this.heightHardLimits[gender][type]['add'];

    return (age * mul) + add;
  }
}
